import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import { Role } from "@/resources/interfaces";
import type { RouteConfig } from "vue-router";

const Index = () => import("@/views/dashboard/Index.vue");
const ApplicationList = () => import("@/views/application/ApplicationList.vue");
const ApplicationDetail = () =>
  import("@/views/application/ApplicationDetail.vue");
const ConsumerCreditProfile = () =>
  import("@/views/application/ConsumerCreditProfile.vue");
const BusinessList = () => import("@/views/business/BusinessList.vue");
const BusinessDetail = () => import("@/views/business/BusinessDetail.vue");
const ConfigList = () => import("@/views/config/ConfigList.vue");
const ConfigDetail = () => import("@/views/config/ConfigDetail.vue");
const UsStates = () => import("@/views/config/UsStates.vue");
const IsoList = () => import("@/views/iso/IsoList.vue");
const IsoDetail = () => import("@/views/iso/IsoDetail.vue");
const SyndicatorList = () => import("@/views/syndicator/SyndicatorList.vue");
const SyndicatorDetail = () =>
  import("@/views/syndicator/SyndicatorDetail.vue");
const SyndicatorProfit = () =>
  import("@/views/syndicator/SyndicatorProfit.vue");
const OfferCalculator = () => import("@/views/offer/OfferCalculator.vue");
// const Activation = () => import("@/views/offer/Activation.vue");
const PaymentGenerator = () =>
  import("@/views/application/PaymentGenerator.vue");
const GeneralLedger = () => import("@/views/accounting/GeneralLedger.vue");
const PaymentSchedule = () => import("@/views/accounting/PaymentSchedule.vue");
const UploadActivityDetail = () =>
  import("@/views/accounting/UploadActivityDetail.vue");
const UploadActivityList = () =>
  import("@/views/accounting/UploadActivityList.vue");
const ProductList = () => import("@/views/product/ProductList.vue");
const ProductDetail = () => import("@/views/product/ProductDetail.vue");
const SyndicationSetup = () =>
  import("@/views/application/SyndicationSetup.vue");
const ContractDrafting = () => import("@/views/offer/ContractDrafting.vue");
const FileDownload = () => import("@/views/file/FileDownload.vue");
const UserList = () => import("@/views/user/UserList.vue");
const UserDetail = () => import("@/views/user/UserDetail.vue");
const Profile = () => import("@/views/user/Profile.vue");
const CustomerServiceTaskList = () =>
  import("@/views/customerServiceTask/CustomerServiceTaskList.vue");
const SitePolicy = () => import("@/views/pages/SitePolicy.vue");
const VisitorLogin = () => import("@/views/pages/VisitorLogin.vue");

Vue.use(Router);

const isKAM = process.env.VUE_APP_KAM === "1";
const filterRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  return routes.filter((route) => {
    if (route.children) {
      route.children = filterRoutes(route.children);
    }
    return !route.meta?.disabled;
  });
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: filterRoutes([
    {
      path: "/accounting",
      component: Index,
      redirect: "/accounting/general-ledger",
      children: [
        {
          path: "general-ledger",
          name: "General Ledger",
          component: GeneralLedger,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "payment-schedule/:date?",
          name: "Payment Schedule",
          component: PaymentSchedule,
          meta: { disabled: isKAM },
        },
        {
          path: "upload-activity",
          name: "Upload Activities",
          component: UploadActivityList,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "/upload-activity/:id",
          name: "Upload Activity",
          component: UploadActivityDetail,
        },
      ],
    },
    {
      path: "/application",
      component: Index,
      redirect: isKAM ? "/application/advance" : "/application/submission",
      children: [
        {
          path: "submission",
          name: "Submission List",
          component: ApplicationList,
          meta: {
            keepAlive: true,
            disabled: isKAM,
          },
          props: { isAdvance: false },
        },
        {
          path: "advance",
          name: "Advance List",
          component: ApplicationList,
          meta: {
            keepAlive: true,
          },
          props: { isAdvance: true },
        },
        {
          path: "add",
          name: "New Application",
          component: ApplicationDetail,
          props: { add: true },
        },
        {
          path: ":id",
          name: "Application Detail",
          component: ApplicationDetail,
          meta: { keepAlive: false },
        },
        {
          path: ":applicationId/contract",
          name: "Contract Drafting",
          component: ContractDrafting,
        },
        {
          path: ":applicationId/syndication",
          name: "Syndication",
          component: SyndicationSetup,
        },
        // {
        //   path: ":applicationId/activation",
        //   name: "Funding",
        //   component: Activation,
        // },
        {
          path: ":id/payment-generator",
          name: "Payment Generator",
          component: PaymentGenerator,
        },
      ],
    },
    {
      path: "/consumer-credit-profile",
      component: Index,
      children: [
        {
          path: ":id",
          name: "Consumer Credit Profile",
          component: ConsumerCreditProfile,
        },
      ],
    },
    {
      path: "/customer-service-task",
      component: Index,
      redirect: "/customer-service-task/index",
      meta: { disabled: isKAM },
      children: [
        {
          path: "index",
          name: "CS Task List",
          component: CustomerServiceTaskList,
          meta: {
            keepAlive: true,
          },
        },
      ],
    },
    {
      path: "/business",
      component: Index,
      redirect: "/business/index",
      children: [
        {
          path: "index",
          name: "Merchant List",
          component: BusinessList,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "add",
          name: "New Business",
          component: BusinessDetail,
          props: { add: true },
        },
        {
          path: ":id",
          name: "Business Detail",
          component: BusinessDetail,
          meta: {
            keepAlive: false,
          },
        },
      ],
    },
    {
      path: "/config",
      component: Index,
      redirect: "/config/index",
      children: [
        {
          path: "index",
          name: "Config List",
          component: ConfigList,
          meta: {
            keepAlive: true,
            requireAdmin: true,
          },
        },
        {
          path: "us-states",
          name: "States of USA",
          component: UsStates,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: ":key",
          name: "Config Item",
          component: ConfigDetail,
        },
      ],
    },
    {
      path: "/iso",
      component: Index,
      redirect: "/iso/index",
      children: [
        {
          path: "index",
          name: "ISO List",
          component: IsoList,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "add",
          name: "New ISO",
          component: IsoDetail,
          props: { add: true },
        },
        {
          path: ":id",
          name: "ISO Detail",
          component: IsoDetail,
        },
      ],
    },
    {
      path: "/syndicator",
      component: Index,
      redirect: "/syndicator/index",
      children: [
        {
          path: "index",
          name: "Syndicator List",
          component: SyndicatorList,
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "add",
          name: "New Syndicator",
          component: SyndicatorDetail,
          props: { add: true },
        },
        {
          path: ":id",
          name: "Syndicator Detail",
          component: SyndicatorDetail,
        },
        {
          path: ":id/profit",
          name: "Syndicator Profit Report",
          component: SyndicatorProfit,
        },
      ],
    },
    {
      path: "/offer",
      component: Index,
      children: [
        {
          path: "calculator",
          name: "Offer Calculator",
          component: OfferCalculator,
        },
      ],
    },
    {
      path: "/file",
      component: Index,
      children: [
        {
          path: ":id",
          name: "File Download",
          component: FileDownload,
        },
      ],
    },
    {
      path: "/product",
      redirect: "/product/index",
      component: Index,
      meta: { disabled: isKAM },
      children: [
        {
          path: "index",
          name: "All Products",
          component: ProductList,
          meta: {
            keepAlive: true,
            requireAdmin: true,
          },
        },
        {
          path: ":id",
          name: "Product Detail",
          component: ProductDetail,
        },
      ],
    },
    {
      path: "/user",
      component: Index,
      redirect: "/user/index",
      children: [
        {
          path: "index",
          name: "User List",
          component: UserList,
          meta: {
            keepAlive: true,
            requireAdmin: true,
          },
        },
        {
          path: "add",
          name: "New User",
          component: UserDetail,
          props: { add: true },
        },
        {
          path: "profile",
          name: "Profile",
          component: Profile,
        },
        {
          path: ":id",
          name: "User Detail",
          component: UserDetail,
          meta: { keepAlive: false },
        },
      ],
    },
    {
      path: "/visitor",
      name: "Visitor Login",
      component: VisitorLogin,
      meta: { isPublic: true },
    },
    {
      path: "/",
      component: Index,
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/dashboard/Dashboard.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/pages/Index.vue"),
      children: [
        {
          name: "Lock",
          path: "lock",
          component: () => import("@/views/pages/Lock.vue"),
        },
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/pages/Login.vue"),
          meta: { isPublic: true },
        },
        {
          name: "Terms of Use",
          path: "terms-of-use",
          component: SitePolicy,
          meta: { isPublic: true },
        },
        {
          name: "Privacy Policy",
          path: "privacy-policy",
          component: SitePolicy,
          meta: { isPublic: true },
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/pages/Index.vue"),
      children: [
        {
          name: "404 Error",
          path: "",
          component: () => import("@/views/pages/Error.vue"),
        },
      ],
    },
  ]),
});

router.beforeEach(async (to, from, next) => {
  const queryToken = to.query.token;
  const isVisitor = store.state.user?.role == Role.VISITOR;

  if (queryToken) {
    if (isVisitor || !localStorage.getItem("token")) {
      store.commit("SET_TOKEN", queryToken);
    }
  }

  const { loggedIn, passwordExpired } = store.getters;

  if (!loggedIn && !to.meta?.isPublic && to.path !== "/login") {
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else if (loggedIn) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else if (to.path !== "/user/profile" && passwordExpired) {
      next({ path: "/user/profile" });
    } else if (
      to.path !== "/visitor" &&
      to.path !== "/offer/calculator" &&
      to.path !== "/404" &&
      isVisitor
    ) {
      next({ path: "/404" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  const { isAdmin } = store.getters;
  if (to.name) {
    document.title = to.name + " - Lonesome Wrench";
  }
  if (to.meta?.requireAdmin && !isAdmin) {
    store.commit("SET_FORBIDDEN", true);
  }
});

export default router;
