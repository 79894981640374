<template>
  <index-page>
    <v-container class="error-page fill-height" tag="section">
      <v-row class="text-center" justify="center">
        <v-col cols="auto">
          <h1 class="title white--text font-weight-black">403</h1>

          <div class="display-3 white--text mb-5 mt-10">Forbidden :(</div>

          <v-btn depressed color="grey darken-3" @click="back">Home</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </index-page>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import IndexPage from "./Index.vue";

@Component({
  components: {
    IndexPage,
  },
})
export default class Forbidden extends Vue {
  back() {
    window.location.href = "/";
  }
}
</script>
